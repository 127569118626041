<script>
import { cloudImageBaseUrl } from '@/CONF.js'

import { room_list, explainList, oddsExplain } from '@/api/home.js'

export default {
    name:'RoomList',
    components:{
        Header: () => import('components/base/Header'),     
    },
    data() {
        return {
            cloudImageBaseUrl,
            content: '',
            buillTure: false, //玩法细则窗口
            imgUrlTitle: '//cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/dt_classic/', //oss图片地址头
            oddsContent: '',
            oddsTitle: '',
            oddsExplainTrue: false, //赔率说明窗口
            list: [],
            type: '',
            game_type: '', //游戏类型
            game_name: '', //游戏名字

            header: {
                mainTitle: "",
            }
        };
    },
    mounted() {     
        this.game_type = this.$route.query.type;
        this.header.mainTitle = this.game_name = this.$route.query.name;
        this.default_load();
    },
    methods: {
        
        //赔率说明
        getoddsExplain(roomtype, it) {
            this.oddsTitle = it.name;
            oddsExplain(this.game_type, roomtype).then(result => {
                if (result.data.code == 'FAILUE') {
                    this.$message({
                        message: result.data.msg,
                        type: 'error'
                    });
                } else if (result.data.code === 'SUCCESS') {
                    this.oddsContent = result.data.result.content;
                    this.oddsExplainTrue = true;
                    this.$nextTick(function() {
                        this.CustomScroll(this.$refs.oddsRule.id);
                    });
                }
            })
        },
        //玩法细则
        showRules() {
            this.buillTure = true;
            explainList(this.game_type).then(result => {
                if (result.data.code == 'FAILUE') {
                    this.$message({
                        message: result.data.msg,
                        type: 'error'
                    });
                } else if (result.data.code === 'SUCCESS') {
                    this.content = result.data.result.content;
                    this.$nextTick(function() {
                        this.CustomScroll(this.$refs.gameRule.id);
                    });
                }
            })
        },
        //回退页面
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push('/home');
            } else {
                this.$router.go(-1);
            }
        },
        default_load() {
            room_list(this.game_type).then(result => {
                if (result.data.code == 'FAILUE') {
                    this.$message({
                        message: result.data.msg,
                        type: 'error'
                    });
                } else if (result.data.code === 'SUCCESS' && result.data.result.length != 0) {
                    this.list = result.data.result;
                }
            })
        },
        chat_path(it) {
            let game = {
                type: this.game_type,
                room: it.room_num
            };
            let url_path = '';
            if (this.game_type.indexOf('28')>-1 && this.game_type !== 'pc28one') {
                url_path = '/t?type=' + this.game_type + '&room=' + it.room_num + '&name=' + this.game_name + '&room_type=' + it.name;
            } else {
                url_path = '/dt_classic/dt_classic_home?type=' + this.game_type + '&room=' + it.room_num + '&name=' + this.game_name + '&room_type=' + it.name;
            }
            localStorage.setItem('game', JSON.stringify(game));
            this._linkTo({
                path: '/t',
                query: {
                    type: this.game_type,
                    room: it.room_num,
                    name: this.game_name,
                    room_type: it.name
                }
            })
            // this.$router.push({ path: url_path });
        },
        goto(url,item) {
            this.$router.push({
                path:url,
                query:{
                    title:'('+item.name+')'+getWord('odd_rules'),
                    roomnum:item.room_num,
                    gametype:this.game_type,
                },
            });
        }
    },
    watch:{

    },
};
</script>
<template>
    <section id="room-list" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-3'">
            <Header 
                :mainTitle="header.mainTitle"
            >
            </Header>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <Header
                :mainTitle="header.mainTitle"
                :backHome=true
            />
        </template>
        <nav>
            <h5 @click='showRules' v-if="_TEMPLATE!=='template-3'">
                <i class="iconfont icon-info"></i>
                {{ getWord('game_rules') }}
            </h5>
            <ul>
                <li v-for="(item,index) in list" v-if="item.status === 1" @click="chat_path(item)" :key="index" :style="{ backgroundImage: 'url('+cloudImageBaseUrl+'dt_classic/room'+(index+1)+'.png)' }">
                    <h4>
                        {{item.name}}
                        <span>{{item.en_name}}</span>
                    </h4>
                    <span>{{item.describe}}</span>
                    <template v-if="_TEMPLATE!=='template-3'">
                        <h6 @click.stop="getoddsExplain((index+1),item)">{{ getWord('odd_rules') }}</h6>
                    </template>
                    <template v-else>
                        <h6 @click.stop="goto('/pc28/GameRule',item)">{{ getWord('odd_rules') }}</h6>
                    </template>
                </li>
            </ul>
        </nav>
        
        <!-- 玩法细则窗口 -->
        <transition name="slide-fade">
            <div class="bulletin" v-if="buillTure">
                <div class="bulltitle">{{game_name}}{{ getWord('game_rules') }}</div>
                <div class="bullCon" id="game-rule" ref="gameRule" v-html="content"></div>
                <div class="bullbottom" @click="buillTure = !buillTure">{{ getWord('close') }}</div>
            </div>
        </transition>
        <!-- 赔率说明 -->
        <transition name="slide-fade">
        <div class="bulletin" v-if="oddsExplainTrue">
            <div class="bulltitle">{{oddsTitle}}{{ getWord('odd_rules') }}</div>
            <div class="bullCon" id="odds-rule" ref="oddsRule" v-html="oddsContent"></div>
            <div class="bullbottom" @click="oddsExplainTrue = !oddsExplainTrue">{{ getWord('close') }}</div>
        </div>
        </transition>
    </section>
</template>
<style scoped lang='scss'>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

#room-list {

    &.template-3 {

        ul {

            li {

                h6 {
                    border-radius:3px;
                    padding: 0.025rem .125rem;
                    font-size: .28rem;
                }
            }
        }
    }

    nav {
        text-align: right;

        h5 {
            font-size: .3rem;
            color: #3985E1;
            text-align: right;
            margin: .25rem;
            margin-bottom: 0;
            font-weight: 300;
            display: inline-block;
        }
    }

    ul {
        margin: .25rem;
        list-style: none;
        text-align: left;

        li {
            background-repeat: no-repeat;
            height: 2.7rem;
            background-size: cover;
            margin-bottom: .25rem;
            color: #ffffff;
            padding: .25rem;
            box-sizing: border-box;
            position: relative;

            h4 {
                font-size: .42rem;
                font-weight: 500;
            }

            span {
                font-size: .34rem;
                font-weight: 300;
                display: block;
                line-height: .6rem;
            }

            h6 {
                border: 1px solid #fff;
                display: inline-block;
                padding: .125rem;
                border-radius: 0 8px;
                position: absolute;
                right: .125rem;
                top: .125rem;
                font-weight: 300;
                font-size: .24rem;
            }
        }
    }
}

.bulletin {
    width: 6rem;
    height: 8rem;
    margin: 0 auto;
    position: absolute;
    top: 25%;
    left: 10%;
}

.bulletin>div {
    position: relative;
    z-index: 10
}

.bulletin:after {
    content: '';
    height: 100%;
    width: 100%;
    position: fixed;
    background: rgba(0,0,0,.3);
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bulltitle {
    height: 1rem;
    background: #4796FC;
    line-height: 1rem;
    text-align: center;
    color: #FFF;
    font-size: 0.3rem;    
    border-radius: 15px 15px 0 0;
}

.bullCon {
    word-break: break-all;
    width: 5.6rem;
    height: 5.8rem;
    overflow-y: auto;
    border-bottom: 1px solid #CCC;
    padding: 0.1rem 0.2rem;
    font-size: 0.3rem;
    background: #fff;
}

.bullbottom {
    line-height: 1rem;
    height: 1rem;
    text-align: center;
    font-size: 0.3rem;
    background: #fff;
    border-radius:  0 0 15px 15px;
}

.bullback {
    height: 100%;
    width: 100%;
    position: fixed;
    background: #000;
    z-index: 9999;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
</style>